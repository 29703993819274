import Splide from "@splidejs/splide";

class ProductCarousel {
    constructor(selector) {
        this.selector = selector;
        let perPage = this.selector.getAttribute('data-columns');
        new Splide(this.selector, {
            type: 'slide',
            arrows: true,
            pagination: false,
            autoplay: false,
            autoWidth: true,
            perPage: perPage,
            gap: '15px',
            breakpoints: {
                1360: {
                    perPage: perPage - 1,
                },
                1000: {
                    perPage: perPage - 2,
                },
            }
        }).mount();
    }
}

export default ProductCarousel;