import ProductCarousel from "../productCarousel";
import Splide from "@splidejs/splide";
import MegaMenu from "../megaMenu";

let productCarousel = document.querySelectorAll('.js-product-carousel');
if (productCarousel.length > 0) {
    productCarousel.forEach(pC => {
        new ProductCarousel(pC);
    })
}

let inspirationCarousel = document.querySelectorAll('.js-inspiration-carousel');
if (inspirationCarousel.length > 0) {
    inspirationCarousel.forEach(iC => {
        let perPage = iC.getAttribute('data-columns');
        new Splide(iC, {
            type: 'slide',
            arrows: true,
            pagination: false,
            autoplay: false,
            autoWidth: true,
            perPage: perPage,
            gap: '40px',
            breakpoints: {
                1360: {
                    perPage: perPage - 1,
                    gap: '25px'
                },
                1000: {
                    perPage: perPage - 2,
                    gap: '25px'
                },
            }
        }).mount();
    })
}

let mainNavigation = document.querySelector('.js-navigation');
if (mainNavigation !== null) {
    let mega = mainNavigation.querySelectorAll(':scope > .menu-item.menu-item-has-children');
    if (mega.length > 0) {
        mega.forEach(m => {
            new MegaMenu(m);
        })
    }
}

let previous_scroll = 0;
let header = document.querySelector('.js-header');
let productBar = document.querySelector('.js-product-bar');

if (header !== null) {
    window.addEventListener('scroll', ev => {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st <= 0) {
            if (header.classList.contains('fixed')) {
                header.classList.remove('fixed');
            }
            if (header.classList.contains('scrolled')) {
                header.classList.remove('scrolled');
            }
        }
        if (st > previous_scroll) {
            if (header.classList.contains('fixed')) {
                header.classList.remove('fixed');
                if (productBar !== null) {
                    productBar.style.removeProperty('top');
                }
            }
            if (st > header.offsetHeight) {
                if (!header.classList.contains('scrolled')) {
                    header.classList.add('scrolled');
                }
            }
        } else if (st < previous_scroll) {
            if (st > header.offsetHeight) {
                if (!header.classList.contains('fixed')) {
                    header.classList.add('fixed');
                    if (productBar !== null) {
                        if (productBar.classList.contains('is-shown')) {
                            productBar.style.top = (header.offsetHeight - 3) + 'px';
                        }
                    }
                }
            }
        }
        previous_scroll = st <= 0 ? 0 : st;
    });
}