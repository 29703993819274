class MegaMenu {
    constructor(selector) {
        this.selector = selector;
        let menu = this.selector.querySelector('.mega-menu__menu');

        this.first_element = menu.querySelector(':scope > .menu-item:first-child');
        this.first_element.classList.add('active');

        this.loadProduct(this.first_element);

        this.menu_elements = menu.querySelectorAll(':scope > .menu-item');
        if (this.menu_elements.length > 0) {
            this.menu_elements.forEach(me => {
                me.addEventListener('mouseleave', e => {
                    console.log('tu');
                    this.first_element.classList.add('active');
                });
                me.addEventListener('mouseover', e => {
                    console.log('siema');
                    this.first_element.classList.remove('active');
                    this.loadProduct(me);
                })
            });
        }
    }

    loadProduct(element) {
        let product_container = element.querySelector('[data-element="product"]');
        if (product_container !== null) {
            if (!product_container.classList.contains('loaded')) {
                let category_id = product_container.getAttribute('data-category');
                this._getFromAPI(category_id).then(response => {
                    product_container.innerHTML = response.content;
                    product_container.classList.add('loaded');
                }).catch(error => {
                    console.log(error);
                    product_container.innerHTML = '';
                    product_container.classList.add('loaded');
                })
            }
        }
    }

    _getFromAPI(category_id) {
        let url = '/wp-json/netivo/v1/category/product/' + category_id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function (response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
}

export default MegaMenu;